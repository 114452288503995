<template>
  <div>
    <Pagination
      :to="dataTable.to"
      :total="dataTable.total"
      :currentPage="table.page"
      :nextPage="dataTable.next_page_url"
      :prevPage="dataTable.prev_page_url"
      :handler="paginateLimit"
      class="mb-0"
      @next="arrowPage"
      @back="arrowPage"
      @search="handleSearch"
    />
    <v-row no-gutters justify="end" class="mb-3">
      <v-checkbox
        v-model="myQuestion"
        hide-details
        :label="$t('e_test.my_question_bank')"
        @change="setMyQuestion()"
      ></v-checkbox>
    </v-row>
    <v-data-table
      :headers="tableHeaders"
      :items="dataTable.data"
      :loading="table.loading"
      disable-pagination
      show-expand
      hide-default-footer
      class="elevation-0"
    >
      <template #item.point="{ item }">
        <v-edit-dialog
          v-if="havePermission(item.created_by)"
          :return-value.sync="item.point"
          large
          persistent
          @save="savePoint(item.id, item.point)"
        >
          <v-btn color="primary" class="text-left" text>{{ item.point }}</v-btn>
          <template v-slot:input>
            <v-text-field
              ref="weight"
              v-model="item.point"
              :rules="[
                v => !!v || $t('app.required'),
                v => Number(v) <= 100 || $t('e_test.msg.validate_weight'),
                v => Math.sign(v) === 1 || $t('e_test.msg.validate_minus')
              ]"
              type="number"
              min="0"
              single-line
            />
          </template>
        </v-edit-dialog>
        <span v-else>{{ item.point }}</span>
      </template>
      <template #item.type="{ item }">
        {{ item.type === "pg" ? $t("e_test.multiple_choice") : "Essay" }}
      </template>
      <template #item.question="{item}">
        <span v-html="truncateHtml(item.question)" />
      </template>
      <template #item.action="{item}">
        <v-menu
          v-if="havePermission(item.created_by)"
          transition="slide-x-transition"
          bottom
          right
          rounded="lg"
        >
          <template #activator="{ on, attrs }">
            <v-btn
              fab
              small
              depressed
              icon
              color="primary"
              v-bind="attrs"
              v-on="on"
            >
              <v-icon>mdi-dots-horizontal</v-icon>
            </v-btn>
          </template>

          <v-list>
            <v-list-item @click="edit(item)">
              <v-list-item-title>
                <v-icon class="mr-2" color="primary">
                  mdi-pencil-box-outline
                </v-icon>
                Edit
              </v-list-item-title>
            </v-list-item>
            <v-list-item @click="confirmDelete(item.id)">
              <v-list-item-title>
                <v-icon class="mr-2" color="primary">
                  mdi-delete-forever
                </v-icon>
                {{ $t("app.delete") }}
              </v-list-item-title>
            </v-list-item>
          </v-list>
        </v-menu>
        <v-tooltip v-else left>
          <template v-slot:activator="{ on, attrs }">
            <v-btn v-bind="attrs" v-on="on" fab small depressed icon>
              <v-icon>mdi-dots-horizontal</v-icon>
            </v-btn>
          </template>
          <span>{{ $t("e_test.delete_and_edit_for_creator") }}</span>
        </v-tooltip>
      </template>
      <template #expanded-item="{ headers, item }">
        <QuestionBankDetail
          :headerLength="headers.length"
          :item="item"
          @viewImage="viewImage"
        />
      </template>
    </v-data-table>
    <ViewImage
      :viewFile="viewFile"
      :urlFile="urlFile"
      @close="viewFile = false"
    />
  </div>
</template>

<script>
import truncate from "truncate-html";

let typingTimer;
const doneTypingInterval = 750;

export default {
  props: {
    table: Object,
    dataTable: Object,
    filter: Object
  },
  components: {
    Pagination: () => import("../components/Pagination"),
    ViewImage: () => import("../components/ViewImage"),
    QuestionBankDetail: () => import("../components/QuestionBankDetail")
  },
  data() {
    return {
      urlFile: "",
      viewFile: false,
      isAdmin: this.$store.getters.g_role_type === "ADMIN",
      userId: this.$store.getters.g_user.userId,
      loading: false,
      myQuestion: this.table.my_question,
      tableHeaders: [
        {
          text: this.$i18n.t("e_test.question"),
          align: "left",
          value: "question"
        },
        {
          text: this.$i18n.t("e_test.question_type"),
          value: "type",
          width: 150
        },
        {
          text: this.$i18n.t("e_test.created_by"),
          value: "created_name",
          width: 150
        },
        {
          text: this.$i18n.t("app.subjects"),
          value: "subjects",
          width: 150
        },
        {
          text: this.$i18n.t("app.grade"),
          value: "grade_name"
        },
        { text: this.$i18n.t("e_test.weight"), value: "point", width: 100 },
        {
          text: this.$i18n.t("app.action"),
          sortable: false,
          value: "action",
          width: 100
        }
      ]
    };
  },
  computed: {
    g_user() {
      return this.$store.getters.g_user;
    }
  },
  methods: {
    setMyQuestion() {
      this.$emit("setMyQuestion", this.myQuestion);
    },
    havePermission(person) {
      return this.isAdmin || this.userId === person;
    },
    viewImage(img) {
      if (img) {
        this.viewFile = true;
        this.urlFile = img;
      }
    },
    truncateHtml: html =>
      truncate(html, 10, { byWords: true, stripTags: true }),
    savePoint(id, point) {
      const isValidate = this.$refs.weight.validate();
      this.$emit("point", id, point, isValidate);
    },
    confirmDelete(id) {
      this.$emit("dialogDelete", id);
    },
    edit(dataEdit) {
      this.$emit("dialogEdit", true, dataEdit);
    },
    paginateLimit(page, limit) {
      this.$emit("changeLimit", page, limit);
    },
    arrowPage(page) {
      this.$emit("changePage", page);
    },
    handleSearch(value) {
      clearTimeout(typingTimer);
      typingTimer = setTimeout(() => {
        this.$emit("searching", value);
      }, doneTypingInterval);
    }
  }
};
</script>
